import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconografia",
      "style": {
        "position": "relative"
      }
    }}>{`Iconografia`}</h1>
    <TabLinks items={[{
      title: 'Utilização',
      href: '/design-guidelines/iconography'
    }, {
      title: 'Biblioteca',
      href: '/design-guidelines/iconography/library'
    }]} mdxType="TabLinks" />
    <p>{`Os ícones representam objetos, ações e estados, e são usados ​​para comunicar uma mensagem. Cada ícone foi projetado para
ser simples, amigável e reduzido à sua forma mínima, expressando características essenciais.`}</p>
    <h2 {...{
      "id": "grid-do-icone",
      "style": {
        "position": "relative"
      }
    }}>{`Grid do ícone`}</h2>
    <p>{`O grid de ícones estabelece regras claras para o posicionamento consistente, mas flexível, dos elementos gráficos.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-canvas.png",
        "alt": "Tamanho da tela, preenchimento e área ativa"
      }}></img></p>
    <h2 {...{
      "id": "tamanho-e-peso",
      "style": {
        "position": "relative"
      }
    }}>{`Tamanho e peso`}</h2>
    <p>{`Todos os ícones são construídos em um quadrado de 24px com 2px de preenchimento. Os ícones do sistema usam uma largura de traçado consistente de 2px,
incluindo curvas e ângulos. Ao usar ícones, todos as áreas de clique precisam ter no mínimo 40 pixels. Ícones do mesmo
tamanho devem ter o mesmo peso visual. Um ícone não deve parecer mais pesado ou mais claro que outro ícone do
mesmo tamanho. Os glifos ou ícones de 16px devem sempre ser um ícone preenchido, isso garante que o ícone permaneça legível.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-size.png",
        "alt": "Tamanho do botão e glifo"
      }}></img></p>
    <h2 {...{
      "id": "formas-das-linhas-principais",
      "style": {
        "position": "relative"
      }
    }}>{`Formas das linhas principais`}</h2>
    <p>{`Ao usar as formas principais baseadas no grid, é possível manter consistentes as proporções visuais nos ícones.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/icn-keyline.png",
        "alt": "Tamanho da tela, preenchimento e área ativa"
      }}></img></p>
    <h3 {...{
      "id": "perfeicao-de-pixels",
      "style": {
        "position": "relative"
      }
    }}>{`Perfeição de pixels`}</h3>
    <p>{`Para evitar distorcer um ícone, posicione os ícones “no pixel” transformando as coordenadas X e Y em números inteiros,
sem decimais.`}</p>
    <h3 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h3>
    <p><img parentName="p" {...{
        "src": "/image/icn-pixel.png",
        "alt": "Exemplos de ícones"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      